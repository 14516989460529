.career-timeline {
  padding: 20px;
}

/* Style for timeline item */
.vertical-timeline-element {
  padding: 20px;
  margin-bottom: 30px;
}

/* Style for dates */
.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
  color: #fffffffb;
  margin-left: -9rem;
}

/* Container for images */
.career-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* Axa image */
.career-img-axa {
  width: 200%;
  height: 180%;
  object-fit: contain;
  border-radius: 50px;
  padding-left: 0.37rem;
}

/* Intesa image */
.career-img-intesa {
  width: 85%;
  height: 85%;
  object-fit: contain;
  border-radius: 50px;
  padding-left: 0rem;
}

/* Color of the vertical line */
.vertical-timeline::before {
  background: #333333;
}

@media (max-width: 768px) {
  .vertical-timeline-element-content .vertical-timeline-element-date {
    float: none;
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .career-timeline {
    padding: 10px;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    padding: 0.5em 0;
    font-size: 12px;
  }
}
