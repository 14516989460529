:root {
  --color-white: #fff;
  --color-black: #000;
  --transition-time: 250ms;
  --transition-method: ease-in-out;
  --card-height: 440px;
  --card-width: 840px;
  --card-hover-offset-x: 40px;
  --card-hover-offset-y: 60px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

body {
  color: #676767;
  line-height: 1.5;
}

.card-movie-wrapper {
  position: relative;
  width: var(--card-width);
  height: var(--card-height);
  box-shadow: 0px 0px 100px 25px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform var(--transition-time) var(--transition-method);
}

.card-movie-wrapper:hover {
  transform: translate(var(--card-hover-offset-x), var(--card-hover-offset-y));
}

.card-movie-carousel {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all var(--transition-time) var(--transition-method);
}

.card-movie-navigation {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-white);
  z-index: 15;
}

.card-movie-navigation ul {
  position: absolute;
  bottom: 20px;
  right: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-movie-navigation li {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color var(--transition-time) var(--transition-method);
}

.card-movie-navigation li:hover,
.card-movie-navigation li.is-active {
  background-color: #bbb;
}

.card-movie-navigation button {
  position: absolute;
  bottom: var(--card-hover-offset-y);
  right: var(--card-hover-offset-x);
  background-color: transparent;
  border: none;
  color: #aac8ca;
  font-size: 14px;
  cursor: pointer;
  transition: color var(--transition-time) var(--transition-method);
}

.card-movie-navigation button:before {
  content: "Watch Trailer";
  display: block;
}

.card-movie-navigation button.is-playing:before {
  content: "Hide Trailer";
}

.card-movie-navigation button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  border: 6px solid transparent;
  border-left-color: #dce9e9;
}

.card-movie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0;
  transition: opacity var(--transition-time) var(--transition-method);
}

.card-movie--active {
  opacity: 1;
  z-index: 10;
}

.card-movie--light {
  background-color: #effdfd;
}

.card-movie--dark {
  background-color: var(--color-black);
  color: var(--color-white);
}

.card-movie--looper {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/43525/looper.png");
}

.card-movie--tron {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/43525/tron.png");
}

.card-movie--interstellar {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/43525/interstellar.png");
  background-color: #fffffa;
}

.card-movie--garden-state {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/43525/garden-state.png");
}

.card-movie--walter-mitty {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/43525/walter-mitty.png");
}

.card-movie--cloud-atlas {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/43525/cloud-atlas.png");
}
