.career-container {
  background-color: #111a28;
  margin-top: 5rem;
  padding: 20px;
}

.career-title-container {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 2.7rem;
}

.career-title-container h1 {
  color: white;
  font-size: 70px;
  padding-right: 5rem;
}

.career-timeline-container {
  padding-top: 2.5rem;
}

@media (max-width: 768px) {
  .career-container {
    margin-top: 3rem;
    padding: 15px;
  }

  .career-title-container {
    text-align: center;
    margin-bottom: 15px;
    margin-left: -0.1rem;
  }

  .career-title-container h1 {
    font-size: 50px;
    padding-right: 0;
  }

  .career-timeline-container {
    padding-top: 2rem;
  }
}

@media (max-width: 480px) {
  .career-container {
    margin-top: 2rem;
    padding: 10px;
  }

  .career-title-container {
    text-align: center;
    margin-bottom: 10px;
  }

  .career-title-container h1 {
    font-size: 35px;
    padding-right: 0;
  }

  .career-timeline-container {
    padding-top: 1.5rem;
  }
}
