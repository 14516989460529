.two-column-section {
  display: grid;
  grid-template-columns: 3fr 2fr;
  background-color: #111a28;
  color: white;
  padding: 20px;
  padding-top: 5rem;
}

.left-column,
.right-column {
  border-radius: 8px;
}

.greetings {
  text-align: left;
  font-size: 80px;
  margin-bottom: 20px;
}

.main-text {
  text-align: left;
  padding-left: 3.5rem;
}

.p-Wrapper {
  width: 65%;
}

.btn-wrapper {
  display: flex;
  gap: 29rem;
  margin-top: 1rem;
}

.green-btn {
  background-color: #00cf5d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
}

.outline-btn {
  background-color: transparent;
  color: #00cf5d;
  border: 2px solid #00cf5d;
  padding: 8px 18px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
}

@media (max-width: 1370px) {
  .greetings {
    font-size: 60px;
  }

  .p-Wrapper {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .two-column-section {
    grid-template-columns: 1fr;
    padding-top: 3rem;
  }

  .greetings {
    font-size: 50px;
    text-align: center;
  }

  .main-text {
    padding-left: 1.5rem;
    text-align: center;
  }

  .p-Wrapper {
    width: 90%;
  }

  .right-column {
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .btn-wrapper {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .greetings {
    font-size: 32px; /* Riduce la dimensione del font */
    margin-bottom: 2rem; /* Riduce il margine inferiore */
    text-align: center; /* Centra il testo */
  }

  .greetings br {
    display: none; /* Rimuove il break di linea tra HI e I'M */
  }

  .greetings span {
    display: inline-block; /* Assicura che il cursore segua il testo */
    margin-top: 0.5rem; /* Aggiunge spazio sopra il Typewriter */
    text-align: left; /* Allinea il testo del Typewriter a sinistra */
  }

  .typewriter-mobile {
    display: block;
    margin-top: 1rem; /* Aggiunge margine sopra il Typewriter su mobile */
    text-align: center; /* Centra il testo del Typewriter su mobile */
    color: #00cf5d;
  }

  .p-Wrapper {
    width: 100%;
  }

  .green-btn,
  .outline-btn {
    width: 100%;
    text-align: center;
  }

  .main-text {
    align-items: center;
  }

  .right-column {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-column > * {
    width: 80%; /* Mantiene le proporzioni di Earth riducendo la larghezza */
    max-width: 300px; /* Imposta una larghezza massima per il contenuto */
  }

  .hi {
    font-size: 40px; /* Grandezza del testo per HI su mobile */
  }

  .im {
    font-size: 40px; /* Grandezza del testo per I'M su mobile */
  }
}
