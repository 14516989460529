.projects-container {
  background-color: #111a28;
  margin-top: 5rem;
  padding: 20px;
}

.projects-title-container {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
}

.projects-title-container h1 {
  color: white;
  font-size: 70px;
  padding-right: 5rem;
}
