.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #111a28;
  color: white;
  padding-left: 6rem;
  padding-right: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

body {
  margin-top: 4rem;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 3rem;
  align-items: center;
  padding: 0;
  margin: 0;
}

.navbar-links li {
  display: flex;
  align-items: center;
  margin: 0;
}

.navbar-links li a {
  color: #cccccc;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-links li a:hover {
  color: white;
}

.greenParticles {
  color: #00cf5d;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.navbar-links button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.navbar-links button:hover {
  text-decoration: underline;
}

.green-btn {
  color: #fff !important;
  border: 2px solid #00cf5d !important;
  padding: 8px 18px !important;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.green-btn:hover {
  background-color: #00cf5d !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #111a28;
    width: 100%;
    padding: 1rem;
  }

  .navbar-links.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .navbar-links button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 0;
    margin: 0;
    text-decoration: none;
  }

  .navbar-links button:hover {
    text-decoration: underline;
  }
}
