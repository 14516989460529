.card {
  width: 240px;
  height: 272px;
  background-color: #3333335b;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 11px 23px 2px rgba(0, 0, 0, 0.44);
  transition: width 0.3s, height 0.3s;
}

.card-image {
  height: 50%;
  text-align: center;
}

.card-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin-top: 2rem;
}

.card-text {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.card-text p {
  margin: 0;
}

@media (max-width: 1370px) {
  .card {
    width: 190px;
    height: 220px;
  }

  .card-image img {
    margin-top: 1.5rem;
  }
}

@media (max-width: 768px) {
  .card {
    width: 160px;
    height: 190px;
  }

  .card-image img {
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .card {
    width: 120px;
    height: 150px;
  }

  .card-image img {
    margin-top: 0.5rem;
  }

  .card-text p {
    font-size: 0.8rem;
  }
}
