.tech-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #111a28;
  margin-top: -5rem;
}

.tech-title-container {
  width: 100%;
  text-align: left;
  padding-left: 3.5rem;
  margin-bottom: 20px;
  margin-top: 4rem;
}

.tech-title-container h1 {
  color: white;
  font-size: 70px;
}

.tech-technologies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: auto;
}

.tech-technologies-container canvas {
  width: 150px;
  height: 150px;
}

.tech-technologies-container .tech-ball-container {
  margin: 10px;
}

@media (max-width: 768px) {
  .tech-container {
    padding: 15px;
    margin-top: -3rem;
  }

  .tech-title-container {
    text-align: center;
    padding-left: 0;
    margin-bottom: 15px;
  }

  .tech-title-container h1 {
    font-size: 50px;
  }

  .tech-technologies-container canvas {
    width: 120px;
    height: 120px;
  }

  .tech-technologies-container .tech-ball-container {
    margin: 8px;
  }
}

@media (max-width: 480px) {
  .tech-container {
    padding: 10px;
    margin-top: -2rem;
  }

  .tech-title-container {
    text-align: center;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .tech-title-container h1 {
    font-size: 35px;
  }

  .tech-technologies-container canvas {
    width: 90px;
    height: 90px;
  }

  .tech-technologies-container .tech-ball-container {
    margin: 5px;
  }
}
