.overview-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #111a28;
  color: white;
  padding-right: 4rem;
  transition: all 0.3s ease-in-out;
}

.overview-left-column {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: flex-basis 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.overview-right-column {
  flex: 3;
  padding-right: 3.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: flex-basis 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.overview-centered-image img {
  height: auto;
  border-radius: 8px;
}

.overview-title-container {
  margin-bottom: 20px;
  text-align: right;
}

.overview-title-container h2 {
  font-size: 70px;
  margin: 0;
}

.overview-para-container {
  margin-bottom: 20px;
  text-align: right;
  width: 80%;
  margin-bottom: 2rem;
}

.overview-para-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

.overview-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-end;
}

.overview-card {
  background-color: #222d3b;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
}

.overview-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.overview-card p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 1370px) {
  .overview-container {
    padding: 1.5rem;
  }

  .overview-title-container {
    margin-top: -3rem;
  }

  .overview-title-container h2 {
    font-size: 5rem;
  }

  .overview-card {
    flex: 1 1 calc(50% - 1rem);
  }

  .overview-centered-image img {
    height: auto;
    border-radius: 8px;
    width: 180%;
    margin-right: 5rem;
  }
}

@media (max-width: 900px) {
  .overview-container {
    flex-direction: column;
    align-items: center;
  }

  .overview-left-column,
  .overview-right-column {
    width: 100%;
    text-align: center;
  }

  .overview-right-column {
    padding-right: 0;
    align-items: center;
  }

  .overview-para-container {
    text-align: center;
    width: 100%;
  }

  .overview-title-container h2 {
    font-size: 35px;
    text-align: center;
  }

  .overview-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .overview-card {
    width: 100%;
    max-width: 300px;
  }

  .overview-centered-image {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .overview-centered-image img {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .overview-title-container h2 {
    font-size: 35px;
    margin-top: 4rem;
  }

  .overview-card h3 {
    font-size: 1.25rem;
  }

  .overview-cards-container {
    align-items: center;
  }
}
